import { GetPageQuery } from "apollo"
import { BlockRenderer } from "ui"
import { findProperty } from "utils/findProperty"
import TopBannerEligible from "../Smp/TopBannerEligible"
import Breadcrumbs from "./Breadcrumbs"

import ContentText from "../Smp/ContentText"
import FormBlock from "./FormBlock"

type Props = {
  data: GetPageQuery
  locale: string
}

function DynamicFormPage({ data, locale }: Props) {
  const components = {
    topBanner1: TopBannerEligible,
    contentText: ContentText,
    lotteryForm: FormBlock,
  }
  //   const blocks = findProperty(data.page, "blocks")?.blocks || []
  //   const hasBanner = blocks?.some(
  //     (block: any) => block.blockType === "topBanner" || block.blockType === "HeaderWithHeroAndText",
  //   )
  return (
    <>
      <Breadcrumbs data={data} locale={locale} />
      {findProperty(data.page, "blocks")?.blocks?.map((block: any, index: number) => {
        let additionalProps = {}
        if (block.blockType === "contentText") {
          additionalProps = {
            notCentered: true,
            hasBanner: true,
          }
        }

        return (
          <BlockRenderer
            key={index}
            blockType={block.blockType}
            blockData={block}
            components={components}
            props={additionalProps}
            locale={locale}
          />
        )
      })}
    </>
  )
}

export default DynamicFormPage
