import { getUmbracoForm } from "api"
import { extractProperty } from "utils"
import FormMain from "./FormMain"

type Props = {
  data: any
  locale: string
}

const FormBlock = async ({ data, locale }: Props) => {
  const formTitle = extractProperty(data, "formTitle")
  const formId = extractProperty(data, "form")
  const { data: formObject } = await getUmbracoForm(formId, locale)

  return <FormMain data={formObject} formTitle={formTitle} locale={locale} />
}

export default FormBlock
